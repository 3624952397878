<!-- forms -->
<div *ngIf="competitionLaunchForm || contributionForm" class="form-section">
  <div class="button-section">
    <button class="fab-button mat-mini-fab" (click)="close()">
      <i [ngClass]="{ 'icon-arrow-down-bold': smallView, 'icon-arrow-right-bold': !smallView }"></i>
    </button>
  </div>
  <div class="iframe-container">
    <iframe
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      [src]="
        (competitionLaunchForm
          ? 'https://my.forms.app/form/612a90c7bd6e4f7f3a7c1403'
          : 'https://my.forms.app/form/612efd83bd6e4f7f3a8bc4e3'
        ) | safeUrl
      "
      frameborder="0">
    </iframe>
  </div>
</div>

<!-- mobile leftside menu -->
<ng-container *ngIf="showLeftSidenav">
  <div class="left-sidenav-container" fxLayout="row">
    <uni-left-sidenav-collapsed [showSidenav]="false" [showDiscoverSection]="false">
    </uni-left-sidenav-collapsed>
    <div class="sidenav-section" fxLayout="column" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutGap="8px" class="sidenav-inner-section">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="logo-section">
          <a [href]="homePageUrl" class="no-decor cursor-pointer">
            <p class="margin-0 font-bolder font-md-static sparro-grey-900">Uni.xyz</p>
          </a>
          <i class="icon-cross-bold" (click)="close()"></i>
        </div>
        <ng-container *ngFor="let menu of navMenu">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="font-md">{{
                menu.name
              }}</mat-expansion-panel-header>
              <div class="accordion-section">
                <mat-divider></mat-divider>
                <div fxLayout="column" fxLayoutGap="5px">
                  <ng-container *ngFor="let item of menu.subMenu">
                    <a [href]="item.url" class="no-decor">
                      <div
                        fxLayout="row"
                        fxLayoutAlign="flex-start center"
                        fxLayoutGap="8px"
                        class="inner-container">
                        <uni-image
                          [src]="item.icon"
                          [alt]="item.name"
                          [width]="24"
                          [height]="24"
                          classes="fit-cover">
                        </uni-image>
                        <span class="font-sm sparro-grey-900">{{ item.name }}</span>
                      </div>
                    </a>
                  </ng-container>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>

        <mat-divider class="margin-bottom-2"></mat-divider>

        <a
          [href]="homePageUrl + 'about/designers/institutional-access'"
          target="_blank"
          class="no-decor"
          ><section>Institutional Access</section></a
        >
        <a [href]="homePageUrl + 'about/business/sponsor'" target="_blank" class="no-decor"
          ><section>Promote your Brand</section></a
        >
        <a href="https://discord.com/invite/zWSfz2AtfQ" target="_blank" class="no-decor"
          ><section>Join Discord</section></a
        >

        <mat-divider class="margin-bottom-2"></mat-divider>

        <a href="https://helpdesk.uni.xyz/" class="no-decor">
          <section>Help Center</section>
        </a>

        <a href="https://uni.xyz/policy" class="no-decor">
          <section>Platform policies</section>
        </a>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="bottom-section"
        *ngIf="!user">
        <a [href]="getLoginUrl()" class="no-decor">
          <button mat-button class="text sparro-grey-900">Log in</button>
        </a>
        <a [href]="getSignUpUrl()" class="no-decor">
          <button mat-button class="text font-bolder button-orange">Join UNI</button>
        </a>
      </div>
    </div>
  </div>
</ng-container>

<div [ngClass]="{ 'margin-x-2': smallView, 'margin-x-8': !smallView }">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header-padding">
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <button
        class="icon-hamburger"
        mat-icon-button
        *ngIf="smallView"
        (click)="openSidenav()"></button>
      <a [href]="homePageUrl" class="no-decor cursor-pointer">
        <uni-image
          [src]="blackLogo ? uniBlackLogo : uniLogo"
          alt="UNI logo"
          [height]="40"
          auxClassList="header-uni-logo">
        </uni-image>
      </a>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="flex-end center"
      fxLayoutGap="32px"
      [ngStyle]="{ 'margin-right': user ? '220px' : '160px' }">
      <ng-container *ngIf="smallView; else dropdownMenuView"> </ng-container>
      <ng-template #dropdownMenuView>
        <a
          [href]="homePageUrl + 'about/designers/institutional-access'"
          target="_blank"
          class="no-decor font-bold"
          >Institutional Access</a
        >
        <a
          [href]="homePageUrl + 'about/business/sponsor'"
          target="_blank"
          class="no-decor font-bold"
          >Promote your Brand</a
        >
        <a href="https://discord.com/invite/zWSfz2AtfQ" target="_blank" class="no-decor font-bold"
          >Join Discord</a
        >
        <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-y-2 test-class">
          <span class="font-bold">Services</span>

          <button
            mat-icon-button
            [matMenuTriggerFor]="dropDownMenu"
            #triggered="matMenuTrigger"
            #menuTrigger="matMenuTrigger">
            <i
              [ngClass]="{
                'icon-arrow-up-bold font-md': triggered.menuOpen,
                'icon-arrow-down-bold font-md': !triggered.menuOpen
              }"></i>
          </button>
          <mat-menu #dropDownMenu="matMenu" xPosition="before">
            <div class="services-sec">
              <div fxLayout="row" fxLayoutGap="4px">
                <ng-container *ngFor="let menu of navMenu">
                  <div>
                    <p class="font-bold margin-left-4 margin-top-0 margin-bottom-2">
                      {{ menu.name }}
                    </p>
                    <div>
                      <ng-container *ngFor="let item of menu.subMenu">
                        <a [href]="item.url" class="sparro-grey-900 header-submenu no-decor">
                          <div
                            fxLayout="row"
                            fxLayoutAlign="flex-start center"
                            fxLayoutGap="8px"
                            class="inner-container">
                            <uni-image
                              [src]="item.icon"
                              [alt]="item.name"
                              [height]="40"
                              [width]="40"
                              classes="fit-cover">
                            </uni-image>
                            <div
                              fxLayout="column"
                              fxLayoutGap="8px"
                              fxLayoutAlign="center flex-start">
                              <span class="title">{{ item.name }}</span>
                              <span class="subtext">{{ item.subtext }}</span>
                            </div>
                          </div>
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
      </ng-template>
      <uni-header [user]="user" [search]="false" [onlyDesktopView]="true"> </uni-header>
    </div>
  </div>
</div>
