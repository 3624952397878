export class Image {
  constructor(public src: string, public alt: string, public mobile_src?: string) {}
}

export const FEATURES = {
  IMAGES: [
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature1.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature2.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature3.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature4.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature5.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature6.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature7.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/2featuredon/feature8.svg'
  ],
  PRODUCTS: [
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature.png',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature1.png',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature2.png',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature3.png',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature4.png',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/Productfeature5.png'
  ],
  BUSINESS: [
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/7business/feature1.svg',
      title: 'Guaranteed results'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/7business/feature2.svg',
      title: 'Geo targeted'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/7business/feature3.svg',
      title: '24x7 Support'
    }
  ],
  INSTITUTION: [
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/9institutions/feature1.svg',
      title: 'Transparent analytics'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/9institutions/feature2.svg',
      title: 'Global recognition'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/9institutions/feature3.svg',
      title: 'Work flexibly'
    }
  ],
  KEY: [
    {
      title: 'Profile',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Profile3d.svg',
      text: 'Build your profile on UNI to connect with a global community of designers and innovators. Showcase your <strong>skills, projects, and achievements</strong>. \n\nKeep your profile updated to enhance visibility, engage with top creators, and position yourself as a leader in the design community.',
      url: 'https://uni.xyz/profile/'
    },
    {
      title: 'Projects',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Projects3d.svg',
      text: 'Upload and manage your design projects on UNI. Receive valuable <strong>feedback from peers and experts</strong> through our community-driven review system.\n\nLeverage the power of <strong>AI for automated classification</strong> of projects from your presentation board and enhance visibility across the web.',
      url: 'https://uni.xyz/projects/'
    },
    {
      title: 'Journal',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Journal3d.svg',
      text: 'Document your design process and share insights with UNI’s modern in-browser text editor. Start your own <strong>blog, engage in digital journalism</strong>, or keep your followers updated with regular project updates and company news. \n\n<strong>Stay connected and transparent with our community.</strong>',
      url: 'https://uni.xyz/journal/'
    },
    {
      title: 'Publications',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Publications3d.svg',
      text: 'Publish your <strong>research papers, reports, and e-books</strong> using UNI’s flipbook reader. Share your comprehensive research and detailed reports with the wider community. \n\nGain recognition for your contributions and make your scholarly content accessible to a global audience.',
      url: 'https://uni.xyz/publications/'
    },
    {
      title: 'Collections',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Collections3d.svg',
      text: 'Discover and save your favorite projects, competitions, images, publications, and journals. Create <strong>personalized collections to organize your interests</strong>. \n\nUse collections to easily access and share curated content, similar to Pinterest’s pin feature or a bookmark.',
      url: 'https://uni.xyz/projects/image/search'
    },
    {
      title: 'Competitions',
      icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Competitions3d.svg',
      text: 'Participate in diverse design competitions on UNI. Solve <strong>real-world challenges</strong>, showcase your creativity, and compete with global talent. \n\nJoin competitions hosted by leading institutions and organizations to gain recognition and enhance your professional portfolio.',
      url: 'https://uni.xyz/competitions/'
    }
  ],
  DESIGNERS_MEMBERSHIP: [
    'https://uni-common-assets.s3.amazonaws.com/businesses/cover/CompetitionCard1.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/membership-sticker.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/mobile.webp',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/desktop.webp',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/detail-page.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/futurly.svg',
    'https://competitions-uni.s3.amazonaws.com/0/82/Jury/IMG_8287.jpg',
    'https://competitions-uni.s3.amazonaws.com/0/142/2018_Belen.JPG',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/comment-card-1.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/membership/comment-card-2.svg'
  ],

  EXPERTS: {
    juror: [
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/detail-page.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/comment-card-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/comment-card-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/mobile-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/desktop-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/mobile-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/desktop-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/cover-image.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/cover-image-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/cover-image-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/cover-image-3.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-3.svg'
    ],
    curator: [
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/detail-page.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/comment-card-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/comment-card-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/mobile-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/desktop-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/laptop.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/curator/cover-image.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-3.svg'
    ],
    mentor: [
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/detail-page.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/comment-card-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/comment-card-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/mobile-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/desktop-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/mobile-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/desktop-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image-3.svg'
    ]
  },

  BUSINESS_PAGE: {
    list: [
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step1image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step1image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/cover/competitionDetailImage.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/cover/CompetitionCard2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/cover/CompetitionCard1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-2-2nd.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step2image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step2image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-3.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step3image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/list/step3image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-4-1st.svg'
    ],
    host: [
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step1image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step1image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/competitionDetailImage.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/cover/CompetitionCard2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/cover/CompetitionCard1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step2image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step2image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-3-2nd.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step3image1.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/host/step3image2.svg',
      'https://uni-common-assets.s3.amazonaws.com/businesses/number-4-2nd.svg'
    ]
  }
};

export const SHOWCASE = {
  PARTNERS: [
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner1.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner2.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner3.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner4.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner5.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner6.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner7.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner8.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner9.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner10.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner11.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner12.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner13.svg',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/10partners/partner14.svg'
  ],
  TESTIMONIES: [
    {
      user: {
        name: 'Misak Terzibasiyan',
        avatar_url:
          'https://competitions-uni.s3.amazonaws.com/0/65/Jury/Misak_Terzibasiyan_UArchitects_copy.jpg',
        designation: 'Winner, DIY Home'
      },
      content: `We found it an interesting challenge/competition to think about modular housing and
        that it is also affordable. At this moment we are doiing several projects in The Netherlands
        about this theme and it was a nice opportunity to share our thoughts on an international platform.`
    },
    {
      user: {
        name: 'Nakisa Haeri',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/13.webp',
        designation: 'Shortlisted, School of Thought'
      },
      content: `Everything happened on time in the competition calendar.
          The site support was great for times when there was a vague point about the contest. Thanks a lot.`
    },
    {
      user: {
        name: 'Paweera Panprommin',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/3.webp',
        designation: 'Honorable mention, The Oasis cultural center competition'
      },
      content: `UNI projects offered intriguing briefs as well as a variety of competition types.
        We thought that the project piqued our interest in the culture and that there were several
        challenging criteria and some key factors that have positively shaped our design.`
    },
    {
      user: {
        name: 'Krzysztof Galant',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/12.webp',
        designation: 'Winner, Terra x Terra'
      },
      content: `UNI competitions are very extraordinary. The breifs challenge designers to confront a wide range of very unusual topics and
        issues of the contemporary world. All of the competition's entries seem to be important for solving these problems.
        I am glad to be part of this community.`
    },
    {
      user: {
        name: 'Katarzyna Lech',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/1.webp',
        designation: 'Honorable mention, The SafeHouse competition'
      },
      content: `The SafeHouse Competition encouraged me to look deeper and understand the problem of homelessness.
        Thanks to that, I am more aware and empathic as a designer.`
    },
    {
      user: {
        name: 'Moeka Hayashi',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/6.webp',
        designation: 'Winner, Elementorize competition'
      },
      content: `We think it is brilliant to have various types of competitions to be chosen to participate in.
        Also, by the requirement of writing a 'journal', not only we can share our design ideas,
        but we can also have a better understanding of other participants' projects!
        Your online system of competition has also successfully adapted to the covid-19 situation now.
        It's a great pleasure to know about uni.xyz.`
    },
    {
      user: {
        name: 'Sulby Gutierrez',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/2.webp',
        designation: 'Winner, Allcart competition'
      },
      content: `As industrial designers, we are always open to new challenges where we can show the social value of industrial design
        to society, and that's why this competition was a perfect opportunity for us.`
    },
    {
      user: {
        name: 'Elisabeth Kathryn',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/4.webp',
        designation: 'Runner-up, Terra x Terra competition'
      },
      content: `The topic of the Terra x Terra workshop and competition was quite interesting and applicable for practitioners.
        I am personally inspired by the instructors, Monish and Karthik, who shared their valuable skills and practical experiences.
        Specifically the whole workflow and considerations behind their built projects. Combining workshop and competition is also
        a great way to let the participants practice their skills right away and get inspiration from the other entries as well.`
    },
    {
      user: {
        name: 'Fady Francis',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/5.webp',
        designation: 'Runner-up, The Oasis cultural center competition'
      },
      content: `Competitions on UNI target key sectors and elements in their briefs that are getting neglected elsewhere.
        It was a great experience, and we're already looking forward to our next competition.`
    },
    {
      user: {
        name: 'Nahal Doosti',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/7.webp',
        designation: 'Honorable Mention, Container city competition'
      },
      content: `The thing that makes Uni different for me was the subject of competition.
        You cant see such competition with these important issues these days. And that was challenging for me.`
    },
    {
      user: {
        name: 'Saranya Harikumar',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/8.webp',
        designation: 'Winner, Canva competition'
      },
      content: `I was looking for design competitions to explore and think out of the box.
        UNI is a great international platform to do that. The competitions hosted by UNI are interesting and challenging.
        It helps in breaking our creative block and have fun designing.
        I am really glad, I stumble upon this platform and to have won a competition hosted by them.`
    },
    {
      user: {
        name: 'Ekin Meşe',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/9.webp',
        designation: "Editor's Choice, Better Bus Stop"
      },
      content: `The accessibility of various competitions in different scales and topics makes UNI one of the best competition sources
        to enter. If you are a student that deals with the design, you can easily pick up a competition about your interest from the
        library of UNI.`
    },
    {
      user: {
        name: 'Antonio Abreu',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/10.webp',
        designation: 'Honorable Mention, Container city competition'
      },
      content: `UNI offers what any undergraduate looks for during or upon their graduation: the industry experience,
        competing for the chosen project, and community support. Container City is by far the best brief I’ve worked on.`
    },
    {
      user: {
        name: 'Mahek Jain',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/11.webp',
        designation: 'Shortlisted, Terra x Terra'
      },
      content: `I think this platform is a perfect opportunity to challenge ourselves,
        to explore more not just in terms of designing but also presentation, time management, and a lot more.
        This platform helps in good networking and makes sure that it brings up talented people by featuring their work.`
    },
    {
      user: {
        name: 'Sara Jahed',
        avatar_url:
          'https://uni-common-assets.s3.amazonaws.com/Landingpage/6testimonial/profilephoto/14.webp',
        designation: 'Honorable mention, The Oasis cultural center competition'
      },
      content: `During the competition, I encountered many questions and problems That the UNI team helped me resolve all
        my questions and problems and helped me choose the right path, and if they weren’t, I could not participate in this competition,
        and the existence of this system would make me not worry about anything. I am very glad. Thanks for everything`
    }
  ],
  PROJECTS: [
    {
      cover: new Image(
        'https://uni-common-assets.s3.amazonaws.com/Landingpage/4keyfeatures/Section3.png',
        'looping tale, uni projects, uni profile, uni journal uni publication, uni discussion, uni agora, build your portfolio'
      ),
      title: 'Looping tale',
      url: 'https://uni.xyz/projects/looping-tale-desc-reimagined-little-red-'
    }
  ]
};

export const IMAGES = {
  MEMBERSHIP: new Image(
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/11Membership/membershipa.png',
    'Uni Membership: Participate in unlimited competitions, get free access to yearbook'
  ),
  COVER: new Image(
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/1hero/Hero.png',
    '',
    'https://uni-common-assets.s3.amazonaws.com/Landingpage/1hero/hero_mobile.jpg'
  )
};

export const NAVMENU = {
  navMenu: [
    {
      name: 'Explore',
      subMenu: [
        {
          name: 'Competitions',
          url: 'https://uni.xyz/competitions/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/competition_green.svg',
          subtext: `Pick your's from the library of 500+ challenges`
        },
        {
          name: 'Projects',
          url: 'https://uni.xyz/projects/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/project_green.svg',
          subtext: 'Explore award-winning projects on UNI'
        },
        {
          name: 'Publications',
          url: 'https://uni.xyz/publications/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/publication_green.svg',
          subtext: 'Portfolio, dissertation, books and more'
        },
        {
          name: 'Journals',
          url: 'https://uni.xyz/journal/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/journal_green.svg',
          subtext: 'Browse and write compelling articles'
        }
      ]
    },
    {
      name: 'Designers',
      subMenu: [
        {
          name: 'Build portfolio',
          url: 'https://uni.xyz/about/designers/portfolio',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/portfolio.svg',
          subtext: 'Build your portfolio - Upload projects, publications, journal.'
        },
        {
          name: 'UNI Membership',
          url: 'https://uni.xyz/about/designers/membership',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/membership_gold.svg',
          subtext: 'Get access to unlimited competitions without any entry fee.'
        },
        {
          name: 'UNI yearbook',
          url: 'https://uni.xyz/journal/exploring-design-like-never-before-desc-/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/yearbook_violet.svg',
          subtext: 'Best designs created by top designers all in one place.'
        },
        {
          name: 'Institutional Access',
          url: 'https://uni.xyz/about/designers/institutional-access/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/ia_red.svg',
          subtext: 'Curated exchange programs to collaborate for university students.'
        }
      ]
    },
    {
      name: 'Experts',
      subMenu: [
        {
          name: 'Become a Curator',
          url: 'https://uni.xyz/about/experts/curator',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/curator.svg',
          subtext: 'Curate your own Architecture & Design competition'
        },
        {
          name: 'Become a Mentor',
          url: 'https://uni.xyz/about/experts/mentor',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/mentor.svg',
          subtext: 'Make an impact. Be a mentor.'
        },
        {
          name: 'Become a Juror',
          url: 'https://uni.xyz/about/experts/juror',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/juror.svg',
          subtext: 'Become a Juror on UNI, get recognized, feel valued'
        }
      ]
    },
    {
      name: 'Business',
      subMenu: [
        {
          name: 'List a competition',
          url: 'https://uni.xyz/about/business/list',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/list.svg',
          subtext: 'List your Design competitions on UNI'
        },
        {
          name: 'Sponsor a competition',
          url: 'https://uni.xyz/about/business/sponsor',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/sponsor.svg',
          subtext: 'Sponsor a competition and promote your brand on UNI'
        },
        {
          name: 'Host a competition',
          url: 'https://uni.xyz/about/business/host',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/host.svg',
          subtext: 'Launch your Architecture & Design competitions on UNI'
        },
        {
          name: 'Sphere by UNI',
          url: 'https://sphere-uni.webflow.io/',
          icon: 'https://uni-common-assets.s3.amazonaws.com/Landingpage/icons/sphere.svg',
          subtext: 'Accelerating global construction with innovative BIM and agile methods '
        }
      ]
    }
  ]
};
