import { NAVMENU } from '@about/core/configs/landing.config';
import { environment } from '@about/env/environment';
import { Component, Input, OnInit } from '@angular/core';
import { HeaderService } from '@sparro/app/core/api/header/header.service';
import { User } from '@sparro/app/core/models/user.model';
@Component({
  selector: 'app-landing-page-header',
  templateUrl: './landing-page-header.component.html',
  styleUrls: ['./landing-page-header.component.scss']
})
export class LandingPageHeaderComponent implements OnInit {
  @Input() smallView: boolean = false;
  @Input() user: User;
  @Input() blackLogo: boolean = false;
  @Input() contributionForm: boolean = false;
  @Input() competitionLaunchForm: boolean = false;

  navMenu = NAVMENU.navMenu;
  uniLogo: string = 'https://uni-common-assets.s3.amazonaws.com/logo/uni/uni-logo.svg';
  uniBlackLogo: string = 'https://uni-common-assets.s3.amazonaws.com/logo/uni/uni-logo-black.svg';
  showLeftSidenav: boolean = false;
  homePageUrl: string = `${environment.serverUrl}`;

  constructor(private headerService: HeaderService) {}

  ngOnInit(): void {
    this.headerService.setHeaderWidth('none');
  }

  openForm(formType: string): void {
    this.showLeftSidenav = false;
    formType === 'contributionForm'
      ? (this.contributionForm = true)
      : (this.competitionLaunchForm = true);
    this.stopScrolling();
  }

  stopScrolling(): void {
    window.onscroll = () => {
      window.scrollTo(window.scrollX, window.scrollY);
    };
  }

  close(): void {
    this.contributionForm = false;
    this.competitionLaunchForm = false;
    this.showLeftSidenav = false;
    window.onscroll = () => {};
  }

  openSidenav(): void {
    this.showLeftSidenav = true;
    this.stopScrolling();
  }

  getSignUpUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-up&continue=${window.location.href}`;
  }

  getLoginUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-in&continue=${window.location.href}`;
  }

  getProfileUrl(): string {
    return `${environment.profileUrl}${this.user.username}`;
  }
}
